import { createRouter, createWebHashHistory  } from 'vue-router'
import homeView from "@/views/home"
import supplierAllBack from "@/views/supplierAllBack"

const routes = [
    { path: '/', component: homeView },
    { path: '/supplierAllBack', component: supplierAllBack }
]

const router = createRouter({
    history: createWebHashHistory (),
    routes
})

export default router
<template>
  <div class="main-container" ref="main">
<!--    <div class="tip">{{tip}}</div>-->
    <swiper
        ref="swiper"
        :initial-slide="initialSlide"
        :modules="[Virtual]"
        virtual
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @beforeDestroy="onSwiperBeforeDestroy"
        @slideChangeTransitionStart="onSlideChangeTransitionStart"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
    >
      <swiper-slide v-for="(item,index) in list" :key="index" :virtualIndex="index">
        <div class="scroll-view">
          <div class="scroll-view__content">
<!--            <template v-if="item.type == 1">-->
<!--              <van-image fit="contain" :width="imgWidth" :height="imgHeight" position="center" ref="img"  @load="imgLoad" :src="'./data/'+type+'/images/'+item.img"></van-image>-->
<!--            </template>-->
            <!-- 目录页 -->
            <template v-if="item.type == 2">
              <div class="catalogue" :style="{width:imgWidth+'px',height:imgHeight+'px'}">
                <img :src="'./data/'+type+'/images/'+item.img" alt="">
                <div class="catalogue_box">
                  <div class="scroll-view">
                    <div class="scroll-view__content">
                      <div v-for="(item,index) in catalogue" :key="index" class="catalogue-cell" :style="{fontSize:cellFontSize}">
                        <div class="catalogue-cell__num">{{(index+1)>9?(index+1):'0'+(index+1)}}</div>
                        <div class="catalogue-cell__box">
                          <div class="catalogue-cell__box-tip" v-if="index == 0">点击页码跳转至对应页面</div>
                          <div class="catalogue-cell__box-title" @click="onCatalogueItemClick(item)">
                            <div class="catalogue-cell__box-title__bd" v-html="item.title"></div>
                            <div class="catalogue-cell__box-title__ft">{{item.desc}}</div>
                          </div>
                          <div class="catalogue-cell__box-list" v-if="item.list.length>0">
                            <div class="catalogue-cell__box-list__btn" :class="currOpen == index?'hide':''" @click="onCatalogueGroupClick(index)">
                              <icon data="@icon/hand.svg"></icon>  <span>点击展开</span>
                            </div>
                            <div class="catalogue-cell__box-list__drop" :class="currOpen == index?'show':''">
                              <div v-for="(catalogue,catalogueIdx) in item.list" :key="catalogueIdx" class="drop-item" @click="onCatalogueItemClick(catalogue)">
                                <div class="drop-item__bd" :style="catalogue.style?catalogue.style:''" v-html="catalogue.title"></div>
                                <div class="drop-item__line"></div>
                                <div class="drop-item__ft" >{{catalogue.desc}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="page-content">
<!--                <div style="position: absolute;top: 5px;left: 5px;font-size: 20px;color: #333333">version 1.1.0</div>-->

<!--              <img :src="'./data/'+type+'/images/'+item.img">-->
<!--                <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>-->
                <img class="imgHot" ref="img1" :width="imgWidth" :height="imgHeight" :src="'./data/'+type+'/images/'+item.img+'?v='+version" :usemap="'#map_'+index" @load="imgLoad" ismap alt="" srcset="" @click="onPageClick(index)">
                <map :name="'map_'+index" >
                  <!--设置一个左上角坐标为（100，100），右下角坐标为（200，200）用来定位矩形的位置-->
                  <area v-for="(hot,hotIdx) in item.hot" :key="hot" shape="rect" :coords="hot.coords" @click="onHotClick(hot,hotIdx)"/>
                </map>
                <div v-for="area_item in item.area" :key="area_item" class="area_box" :style="{width: area_item.w,height: area_item.h,left:area_item.x,top:area_item.y}">
                  <img :src="'./data/'+type+'/images/'+area_item.img+'?v='+version" loading="lazy">
<!--                  <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>-->
                </div>

                <div v-for="wigget_item in wigget" :key="wigget_item" class="wigget_box" :style="wigget_item.style" v-html="wigget_item.content"></div>
              </div>
            </template>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div id="bottom-bar" class="bottom-bar" :style="{bottom:'50%', transform:'translateY('+bottom+'px)',left:imgRight+'px',right:imgRight+'px'}">
      <div class="tool-bar">
        <div class="tool-bar__icon">
          <icon data="@icon/left.svg"></icon>
        </div>

        <div class="tool-bar__pre" @click="onPre">上一页</div>
        <div class="tool-bar__text">
          <span @click="onInput">{{inputNum>9?inputNum:'0'+inputNum}}</span> / <span>{{maxNum}}</span>
        </div>
        <div class="tool-bar__next" @click="onNext">下一页</div>
        <div class="tool-bar__icon">
          <icon data="@icon/right.svg"></icon>
        </div>
      </div>
      <div class="directory-btn" @click="onToCatalogue">
        <span>目</span><span>录</span>
      </div>
    </div>

<!--    <div class="hide-input">-->
<!--      <input ref="numInput" v-model="num" type="number" @blur="onInputBlur" @input="onInputChange">-->
<!--    </div>-->


    <van-popup v-model:show="pickerPopup.show" round position="bottom">
      <van-picker
          :columns="pickerPopup.list"
          :default-index="pickerPopup.defaultIndex"
          @cancel="pickerPopup.show = false"
          @confirm="onPickerPopupConfirm"
      />
    </van-popup>
    <van-popup v-model:show="exhibitPopup.show" :overlay="false" class="exhibit_popup" :safe-area-inset-bottom="true">
      <div class="exhibit_popup__box" :style="{fontSize:cellFontSize}">
<!--        <div class="exhibit_popup__box-close" :style="{top: (imgTop+10)+'px',right:(imgRight+10)+'px'}" @click="onExhibitPopupClose"> <icon data="@icon/close.svg"></icon></div>-->
        <div class="exhibit_popup__box-content">
          <img ref="img2" :width="imgWidth" :height="imgHeight" :src="'./data/'+type+'/images/'+exhibitPopup.obj.content+'?v='+version" :usemap="'#map_img2'" ismap alt="" srcset="">
          <map :name="'map_img2'">
            <!--设置一个左上角坐标为（100，100），右下角坐标为（200，200）用来定位矩形的位置-->
            <area v-for="(hot,hotIdx) in exhibitPopup.obj.hot" shape="rect" :coords="hot.coords" @click="onHotClick(hot,hotIdx)"/>
          </map>
        </div>
        <div class="exhibit_popup__box-back" :style="{bottom: (popup_btn_bottom+15)+'px'}" @click="onExhibitPopupClose">返&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;回</div>
      </div>
    </van-popup>

    <!--    三级弹窗-->
    <van-popup v-model:show="innerPagePopup.show" :overlay="false" class="exhibit_popup" :safe-area-inset-bottom="true">
      <div class="exhibit_popup__box" :style="{fontSize:cellFontSize}">
        <!--        <div class="exhibit_popup__box-close" :style="{top: (imgTop+10)+'px',right:(imgRight+10)+'px'}" @click="onExhibitPopupClose"> <icon data="@icon/close.svg"></icon></div>-->
        <div class="exhibit_popup__box-content">
          <img ref="img3" :width="imgWidth" :height="imgHeight" :src="'./data/'+type+'/images/'+innerPagePopup.obj.content+'?v='+version" :usemap="'#map_img3'" ismap alt="" srcset="">
          <map :name="'map_img3'">
            <!--设置一个左上角坐标为（100，100），右下角坐标为（200，200）用来定位矩形的位置-->
            <area v-for="(hot,hotIdx) in innerPagePopup.obj.hot" shape="rect" :coords="hot.coords" @click="onHotClick(hot,hotIdx)"/>
          </map>
        </div>
        <div class="exhibit_popup__box-back" :style="{bottom: (popup_btn_bottom+15)+'px'}" @click="onInnerPagePopupClose">返&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;回</div>
      </div>
    </van-popup>

    <van-popup v-model:show="swiperPopup.show" :overlay="false" class="exhibit_popup" :safe-area-inset-bottom="true">
      <div class="exhibit_popup__box" :style="{fontSize:cellFontSize}">
        <div class="exhibit_popup__box-content">
          <swiper
              ref="swiperPopupView"
              :initial-slide="swiperPopup.initialSlide"
              :activeIndex="swiperPopup.activeIndex"
              :modules="[Virtual]"
              virtual
              @slideChange="onPopupSlideChange"
              @beforeDestroy="onPopupSwiperBeforeDestroy"
              @slideChangeTransitionStart="onPopupSlideChangeTransitionStart"
              @slideChangeTransitionEnd="onPopupSlideChangeTransitionEnd"
          >
            <swiper-slide v-for="(item,index) in swiperPopup.list" :key="index" :virtualIndex="index">
              <div class="scroll-view">
              <div class="scroll-view__content">
                <div class="page-content">
                  <!--              <img :src="'./data/'+type+'/images/'+item.img">-->
                  <img class="imgHot" :ref="'popup_swiper_img_'+index" :width="imgWidth" :height="imgHeight"
                       :src="'./data/'+type+'/images/'+item.content+'?v='+version" @click="onPopupPageClick(index)">
                </div>
              </div>
            </div>
            </swiper-slide>
          </swiper>

          <div class="exhibit_popup__box-content_btn-group" :style="{bottom: (popup_btn_bottom+15)+'px'}">
            <div class="pre_btn" :class="swiperPopup.pre?'hide':''"   @click="onSwiperPagePre"><icon data="@icon/left.svg"></icon><span>上一页</span> </div>
            <div class="back_btn" @click="onSwiperPagePopupClose">返&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;回</div>
            <div class="next_btn" :class="swiperPopup.next?'hide':''" @click="onSwiperPageNext"><span>下一页</span>  <icon data="@icon/right.svg"></icon></div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import userEqu from "@/utils/userEqu";
import $request from "@/utils/request";

import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import { Virtual } from 'swiper';


import 'swiper/swiper-bundle.css';

import $ from 'jquery'
import {onFirstPause} from "@babel/core/lib/gensync-utils/async";

export default {
  name: 'HomePage',
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    // 获取swiper对象
    swiper () {
      return this.$refs.swiper.$el.swiper
    },
    swiperPopupView () {
      return this.$refs.swiperPopupView.$el.swiper
    },
  },
  watch:{
    imgHeight : function (val){
      // let bottomH = (getComputedStyle(document.documentElement).getPropertyValue("--sab"))
      // bottomH = bottomH.replace("px","")
      // this.$nextTick(()=>{
      //   console.dir(this.$refs.img1)
      // })
      // console.log("######", val, window.innerHeight)
      this.$data.bottom = val/2+2

      // this.$data.tip = "innerHeight:"+window.innerHeight+" imgHeight:"+val +" bottomH:"+bottomH+" bottom:"+ this.$data.bottom
    },
    num(val,oldVal){
        let valIdx = val - 1

        let ignoredAllIdxList = []
        this.$data.list.forEach((item,idx)=>{
            if (item.isIgnored){
                ignoredAllIdxList.push(idx)
            }
        })
        let min = ignoredAllIdxList.filter(item=>item<=valIdx)
        this.$data.inputNum = val-min.length
    }
  },

  data(){
    return {
      version:"18",
      wigget:null,
      catalogue:[],
      catalogueInfo:null,
      list:[],
      maxNum:0,
      type:"",
      urlHead:null,
      appid:null,
      share:null,
      imagePreview:null,
      num:0,
      inputNum:0,
      pickerPopup:{
        show:false,
        list:[],
        defaultIndex:0
      },
      imgWidth:null,
      imgHeight:null,
      bottom:20,
      popup_btn_bottom:20,
      imgTop:10,
      imgRight:0,
      exhibitPopup:{
        show:false,
        obj:{}
      },
      //内页弹窗
      innerPagePopup:{
        show:false,
        initialSlide:0,
        obj:{}
      },
      //翻页弹窗
      swiperPopup:{
        show:false,
        obj:{},
        list:[],
        activeIndex:0,
        pre:false,
        next:false
      },
      cellFontSize:null,
      initialSlide:0,
      keyName:"pageIdxJup360",
      keyName1:"pageIdxJup3601",//三级弹窗附加数据
      currOpen:0,
      tip:"",
      debug:false,
      currPage:{},
      swiperLazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 3,
        loadOnTransitionStart: true
      }
    }
  },
  setup(){
    // Create array with 1000 slides
    const slides = Array.from({ length: 1000 }).map(
        (el, index) => `Slide ${index + 1}`
    );
    return {
      slides,
      Virtual,
    };
  },
  created() {
    console.log("当前版本-"+this.version)

    if (userEqu.isAndroid ||userEqu.isPhone) {
      this.$data.type = "phone"
      this.getData("phone",this.dataFinish)
    } else if (userEqu.isTablet) {
      this.$data.type = "phone"
      this.getData("phone",this.dataFinish)
      
      // window.removeEventListener("resize", this.renderResize, false)
      // window.addEventListener("resize",this.renderResize,false)

      // this.$data.type = "tablet"
      // this.getData("tablet")
      // img_w = 2048
      // img_h = 1536
    } else {

    }

    // let img_w = 1192
    // let img_h = 1685
    //
    // let tempWidth,tempHeight;
    // if (img_w/img_h>=window.innerWidth / window.innerHeight){
    //   if (img_w > window.innerWidth){
    //     tempWidth = window.innerWidth
    //     tempHeight = (img_h * window.innerWidth) / img_w
    //   } else {
    //     tempWidth = img_w
    //     tempHeight = img_h
    //   }
    // } else {
    //   if (img_h > window.innerHeight){
    //     tempHeight = window.innerHeight
    //     tempWidth = (img_w * window.innerHeight) / img_h
    //   } else {
    //     tempWidth = img_w
    //     tempHeight = img_h
    //   }
    // }
    // this.$data.imgWidth = tempWidth
    // this.$data.imgHeight = tempHeight
    //
    //
    //
    // // this.$refs.main.clientHeight
    // //获取底部安全区域高度
    // // let bottomH = (getComputedStyle(document.documentElement).getPropertyValue("--sab"))
    // // bottomH = bottomH.replace("px","")
    //
    // // this.$data.bottom = (window.innerHeight - this.$data.imgHeight) / 2 + parseInt(bottomH) + 5
    // this.$data.imgTop = (window.innerHeight - this.$data.imgHeight) / 2 + 5
    // this.$data.imgRight = (window.innerWidth - this.$data.imgWidth) / 2 + 5
    //
    //
    // this.$data.cellFontSize = tempWidth / 7.50 + "px";

  },
  onMounted() {

  },
  onBeforeUnmount() {
    this.swiper.disable()
    clearInterval(this.currPageTimer)
  },
  onUnmount(){
    this.swiper.disable()
    clearInterval(this.currPageTimer)
  },
  methods:{
    rem(){
      // console.log("rem")
      const width = Math.min(document.documentElement.getBoundingClientRect().width, 768);
      document.documentElement.style.fontSize = width / 7.50 + "px";
    },
    tracking(PID,type,content,name){
      if (PID==0) PID = 1
      console.log("PID:"+PID+" type:"+type+" content:"+content+" name:"+name,+"time")
      
      if(window.parent.addTracking){
        window.parent.addTracking(PID,type,new Date(),content, name);
      }
    },
    renderResize(){
      // if (!this.timer){
      //   setTimeout(()=>{
      //     this.timer = true
      //     this.$nextTick(()=>{
      //       let cookie = {
      //         activeIndex: this.swiper.activeIndex,
      //         popup: this.$data.exhibitPopup.show ? this.$data.exhibitPopup.obj : null,
      //         inner_popup: this.$data.innerPagePopup.show ? this.$data.innerPagePopup.obj : null,
      //       }
      //       // this.$cookies.set(this.$data.keyName, JSON.stringify(cookie), 60 * 10)
      //       localStorage.setItem(this.$data.keyName, JSON.stringify(cookie))
      //       this.timer = false
      //       location.reload()
      //       // this.$router.replace({
      //       //   path:'/supplierAllBack'
      //       // })
      //     })
      //   },400)
      // }
    },
    //数据请求完毕
    dataFinish(){
      this.$data.currPage = {
        pId: this.$data.num,
        idx: this.swiper.activeIndex,
        name: this.$data.list[this.swiper.activeIndex].name,
        content:this.$data.list[this.swiper.activeIndex].content,
        popup:null
      }
      this.tracking(this.$data.currPage.pId,'enter',this.$data.currPage.name,this.$data.currPage.name)

      this.$nextTick(()=>{

        this.$data.num = this.swiper.activeIndex+1


        // if (this.$cookies.isKey(this.$data.keyName)){
        if(localStorage.getItem(this.$data.keyName)){
          // let cookie = this.$cookies.get(this.$data.keyName)
          let cookie = JSON.parse(localStorage.getItem(this.$data.keyName))
          // console.dir(cookie)
          if(cookie.activeIndex!=undefined || cookie.activeIndex!=null){
            this.$data.initialSlide = parseInt(cookie.activeIndex)
            this.$nextTick(()=>{
              this.swiper.slideTo(parseInt(cookie.activeIndex))
              if (cookie.popup){
                this.$data.exhibitPopup.obj = cookie.popup
                this.$data.exhibitPopup.show = true

                if (cookie.inner_popup){
                  this.$data.innerPagePopup.obj = cookie.inner_popup
                  this.$data.innerPagePopup.show = true
                }


                this.currPageTimer = setInterval(()=>{
                  if (this.$data.currPage){
                    clearInterval(this.currPageTimer)
                    this.currPageTimer = null

                    this.$data.currPage.popup = {
                      pId: this.$data.num+"_"+cookie.hotIdx,
                      idx: cookie.hotIdx,
                      name: this.$data.list[this.swiper.activeIndex].hot[cookie.hotIdx].name,
                      content:this.$data.list[this.swiper.activeIndex].hot[cookie.hotIdx].content
                    }
                    // console.dir(this.$data.currPage)
                    this.tracking(this.$data.currPage.popup.pId,'enter',this.$data.currPage.popup.name,this.$data.currPage.popup.name)

                    if(cookie.inner_popup){
                      this.$data.currPage.inner_popup = {
                        pId: this.$data.num+"_"+cookie.hotIdx+"_"+cookie.inner_hotIdx,
                        idx: cookie.inner_hotIdx,
                        name: this.$data.list[this.swiper.activeIndex].hot[cookie.hotIdx].hot[cookie.inner_hotIdx].name,
                        content:this.$data.list[this.swiper.activeIndex].hot[cookie.hotIdx].hot[cookie.inner_hotIdx].content
                      }
                      // console.dir(this.$data.currPage)
                      this.tracking(this.$data.currPage.inner_popup.pId,'enter',this.$data.currPage.inner_popup.name,this.$data.currPage.inner_popup.name)
                    }
                  }
                },100)
              }
              // this.swiper.slideTo(parseInt(this.$cookies.get(this.$data.keyName)))
              // this.$cookies.remove(this.$data.keyName)
              localStorage.removeItem(this.$data.keyName)
            })
          }
        }
        this.rem();

      })

      let url_head = this.$data.urlHead
      let app_id = this.$data.appid
      let share = this.$data.share

      // console.log(url_head)

      // $.ajax({
      //   type: "POST",//方法类型
      //   dataType: "json",//预期服务器返回的数据类型
      //   url: url_head+"/weixin/ticket",
      //   data: {url: window.location.href, appid: app_id},
      //   success: function (res) {
      //     // console.log(res);
      //
      //     wx.config({
      //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //       appId: app_id, // 必填，公众号的唯一标识
      //       timestamp: res.timestamp, // 必填，生成签名的时间戳
      //       nonceStr: res.nonceStr, // 必填，生成签名的随机串
      //       signature: res.signature,// 必填，签名
      //       jsApiList:['getLocalImgData','chooseImage','updateAppMessageShareData','updateTimelineShareData']
      //     });
      //
      //     let shareUrl = share.link;
      //
      //     // console.log(share)
      //
      //     wx.ready(function() {
      //       // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      //       wx.updateAppMessageShareData({
      //         title: share.title, // 分享标题
      //         desc: share.desc, // 分享描述
      //         link: share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //         imgUrl: share.imgUrl, // 分享图标
      //         success: function () {
      //           // 设置成功
      //           // alert('set finish 1')
      //         }
      //       });
      //
      //       wx.updateTimelineShareData({
      //         title: share.title, // 分享标题
      //         desc: share.desc, // 分享描述
      //         link: share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //         imgUrl: share.imgUrl, // 分享图标
      //         success: function () {
      //           // 设置成功
      //           // alert('set finish 2')
      //         }
      //       });
      //     })
      //
      //     wx.error(function(res){
      //       console.log(res)
      //       // Toast("请求失败，请刷新重试");
      //       // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      //     });
      //   },
      //   error : function(err) {
      //     console.log(err);
      //     // Toast("请求失败，请刷新重试");
      //   }
      // });

      // $request({
      //   url: `${url_head}/weixin/ticket`, // json文件地址
      //   method: 'POST',
      //   headers:{
      //     'content-type':"application/json"
      //   },
      //   data: JSON.stringify({url: window.location.href, appid: this.$data.appid})
      // }).then((res)=>{
      //   console.log(res)
      // })
    },
    onSwiper(){

    },
    onSwiperBeforeDestroy(e){
      this.uploadEndTracking()
    },
    onSlideChange(e){
      this.$data.num = e.activeIndex+1
    },
    uploadEndTracking(){
      // if (this.$data.currPage){
      //   if(this.exhibitPopup.show&&this.$data.currPage.popup){
      //     this.tracking(this.$data.currPage.popup.pId,'leave',this.$data.currPage.popup.name,this.$data.currPage.popup.name)
      //   }
      //   this.tracking(this.$data.currPage.pId,'leave',this.$data.currPage.name,this.$data.currPage.name)
      //   this.$data.currPage = {}
      //   this.$data.currPage = null
      // }
    },
    /**
     * 滑动开始
     */
    onSlideChangeTransitionStart(){
      // this.uploadEndTracking()
      // console.log("start")
    },
    /**
     * 滑动结束
     * @param e
     */
    onSlideChangeTransitionEnd(e){
      console.log("end")

      if (this.$data.currPage){
        if(this.exhibitPopup.show&&this.$data.currPage.popup){
          this.tracking(this.$data.currPage.popup.pId,'leave',this.$data.currPage.popup.name,this.$data.currPage.popup.name)
        }
        this.tracking(this.$data.currPage.pId,'leave',this.$data.currPage.name,this.$data.currPage.name)
        this.$data.currPage = {}
        this.$data.currPage = null
      }

      this.$data.currPage = {
        pId: this.$data.num,
        idx: this.swiper.activeIndex,
        name: this.$data.list[this.swiper.activeIndex].name,
        content:this.$data.list[this.swiper.activeIndex].content,
        popup:null
      }
      this.tracking(this.$data.currPage.pId,'enter',this.$data.currPage.name,this.$data.currPage.name)

    },

    // send(){
    //   return new Promise((resolve, reject) => {
    //     $.ajax({
    //       type: "GET",
    //       url: "http://online.jup360.com/nutrition-master/manage/get/time",
    //       async: false,
    //       contentType: 'application/json',
    //       dataType: 'json',
    //       data: {},
    //       success: function (res) {
    //         if (res.success) {
    //           console.log('调用成功：' + res.message);
    //         }
    //         else {
    //           console.log('调用失败：' + res.message);
    //         }
    //       },
    //       error: function (XMLHttpRequest, textStatus, errorThrown) {
    //
    //         // 状态码
    //         console.log(XMLHttpRequest.status);
    //         // 状态
    //         console.log(XMLHttpRequest.readyState);
    //         // 错误信息
    //         console.log(textStatus);
    //         console.log(XMLHttpRequest);
    //         console.log(errorThrown);
    //       }
    //
    //     });
    //   });
    // },

    onPre(){
      this.swiper.slidePrev()
    },
    onNext(){
      this.swiper.slideNext()
    },

    onInput(){

      this.$data.pickerPopup.list = []
      for (let i = 0; i <  this.$data.list.length; i++) {
        this.$data.pickerPopup.list.push(i+1)
      }
      this.pickerPopup.defaultIndex = this.swiper.activeIndex
      this.pickerPopup.show = true

    },
    onPickerPopupConfirm(e){
      this.pickerPopup.show = false
      setTimeout(()=>{

        let ignoredAllIdxList = []
        this.$data.list.forEach((item,idx)=>{
          if (item.isIgnored){
            ignoredAllIdxList.push(idx)
          }
        })
        let min = ignoredAllIdxList.filter(item=>item<=parseInt(e))
        this.swiper.slideTo(parseInt(e)-1+min.length)
        //
        // this.swiper.slideTo(parseInt(e)-1)
      },400)
    },
    onToCatalogue(){
      // console.log("##", this.$data.catalogueInfo)
      this.swiper.slideTo(this.$data.catalogueInfo.pageNum)
      // this.$router.push({path:"/catalogue"})
    },
    imgLoad(){
      // this.$nextTick(()=>{
      //   // console.dir(this.$refs.img1[0].offsetHeight+this.$refs.img1[0].offsetTop)
      //
      //   let bottom = (this.$refs.main.clientHeight -(this.$refs.img1[0].offsetHeight+this.$refs.img1[0].offsetTop))
      //   console.dir(bottom)
      //   bottom = bottom == 0?2:bottom
      //   if (this.$data.bottom == bottom) return
      //   this.$data.bottom = bottom
      //   console.dir(bottom)
      // })
    },
    onPageClick(index) {
      if(this.$data.imagePreview){

        if(this.$data.list[index].imagePreview==false) {
          return;
        }

        let images = [];
        let current = null;

        $(".imgHot").each((i, iter)=>{
          if(i==index) {
            current = $(iter)[0].currentSrc;
          }

          images.push($(iter)[0].currentSrc)
        })

        WeixinJSBridge.invoke('imagePreview', {
          'current' : current,
          'urls' : images
        });
      }
    },
    onHotClick(obj,hotIdx){
      // console.dir(obj)
      console.log("点击内容",obj,hotIdx);
          
      if (obj.content == "") return

      switch (parseInt(obj.type)) {
        case 1: {
          if(this.$data.debug){
            this.$toast(obj.content)
          }

          //点击热区          

          let pid = this.$data.currPage.pId+(this.$data.currPage.popup?"-"+this.$data.currPage.popup.pId:'')+(this.$data.currPage.inner_popup?"-"+this.$data.currPage.inner_popup.pId:'')+"-"+hotIdx
          console.log("pId:"+pid)
          // this.tracking(pid,'click',obj.name,obj.content)


          let cookie = {
            activeIndex: this.swiper.activeIndex,
            popup: this.$data.exhibitPopup.show ? this.$data.exhibitPopup.obj : null,
            hotIdx:hotIdx,
          }
          if (this.$data.innerPagePopup.show){
            // let cookie1 = {
            //   activeIndex: this.swiper.activeIndex,
            //   inner_popup: this.$data.innerPagePopup.obj,
            //   inner_hotIdx:hotIdx,
            // }
            // cookie.hotIdx = this.$data.currPage.popup.idx
            // console.dir(JSON.stringify(cookie1))
            // this.$cookies.set(this.$data.keyName1, JSON.stringify(cookie1), 60 * 10)
            cookie.inner_popup = this.$data.innerPagePopup.obj
            cookie.inner_hotIdx = hotIdx
            cookie.hotIdx = this.$data.currPage.popup.idx
          }

          localStorage.setItem(this.$data.keyName, JSON.stringify(cookie))
          // this.$cookies.set(this.$data.keyName, JSON.stringify(cookie), 60 * 10)

          if (window.parent.openMaterial){
            window.parent.openMaterial(obj.content);
          }
          break;
        }
        case 3:{
          console.log(obj.content)
          this.swiper.slideTo(obj.content)
          break;
        }
        case 4:{
          console.log(obj.content)
          this.onInnerPagePopupShow(obj,this.currPage.popup.idx,hotIdx)
          break
        }
        case 5:{
          console.log("## 多内容弹窗")
          this.onMultiplePagePopupShow(obj)
          break
        }
        default:
          this.onExhibitPopupShow(obj,hotIdx)
          break
      }
    },
    onExhibitPopupShow(obj,hotIdx){
      this.$data.exhibitPopup.obj = obj;
      this.$nextTick(()=>{
        this.$data.exhibitPopup.show = true

        this.$data.currPage.popup = {
          pId: this.$data.num+"_"+hotIdx,
          idx: hotIdx,
          name: this.$data.list[this.swiper.activeIndex].hot[hotIdx].name,
          content:this.$data.list[this.swiper.activeIndex].hot[hotIdx].content
        }
        console.dir(this.$data.currPage)
        this.tracking(this.$data.currPage.popup.pId,'enter',this.$data.currPage.popup.name,this.$data.currPage.popup.name)
      })
    },
    onExhibitPopupClose(){
      this.$data.exhibitPopup.show = false
      this.tracking(this.$data.currPage.popup.pId,'leave',this.$data.currPage.popup.name,this.$data.currPage.popup.name)
      this.$data.currPage.popup = {}
      this.$data.currPage.popup = null
    },
    onInnerPagePopupShow(obj,hotIdx,innerIdx){
      this.$data.innerPagePopup.obj = obj;
      console.log("---")
      console.dir(obj)
      console.log("---")
      this.$nextTick(()=>{
        this.$data.innerPagePopup.show = true

        this.$data.currPage.inner_popup = {
          pId: this.$data.num+"_"+hotIdx+"_"+innerIdx,
          idx: innerIdx,
          name: this.$data.list[this.swiper.activeIndex].hot[hotIdx].hot[innerIdx].name,
          content:this.$data.list[this.swiper.activeIndex].hot[hotIdx].hot[innerIdx].content
        }
        console.dir(this.$data.currPage)
        this.tracking(this.$data.currPage.inner_popup.pId,'enter',this.$data.currPage.inner_popup.name,this.$data.currPage.inner_popup.name)
      })
    },
    onInnerPagePopupClose(){
      this.$data.innerPagePopup.show = false
      this.tracking(this.$data.currPage.inner_popup.pId,'leave',this.$data.currPage.inner_popup.name,this.$data.currPage.inner_popup.name)
      this.$data.currPage.inner_popup = {}
      this.$data.currPage.inner_popup = null
    },
    onCatalogueGroupClick(idx){

      this.$data.currOpen = idx

      // for (let i = 0; i < this.$data.catalogue.length; i++) {
      //   this.$data.catalogue[i].show = false
      // }
      // this.$nextTick(()=>{
      //   this.$data.catalogue[idx].show = true
      // })

    },
      /**
       * 目录跳转
       * @param obj
       */
    onCatalogueItemClick(obj){
      console.log("目录跳转",obj);
      
          let ignoredAllIdxList = []
          this.$data.list.forEach((item,idx)=>{
              if (item.isIgnored){
                  ignoredAllIdxList.push(idx)
              }
          })
          let min = ignoredAllIdxList.filter(item=>item<=parseInt(obj.to))
      this.swiper.slideTo(parseInt(obj.to)-1+min.length)
    },

    //--------多内容弹窗 Start
    onMultiplePagePopupShow(obj){
      if (!obj.mp || obj.mp.length === 0){
        return
      }
      console.log("## 多内容弹窗")

      this.swiperPopup.list = obj.mp;
      this.swiperPopup.pre = true
      this.swiperPopup.next = false
      this.swiperPopup.show = true
      this.$nextTick(()=>{

        this.swiperPopupView.slideTo(0)

        // this.$data.currPage.inner_popup = {
        //   pId: this.$data.num+"_"+hotIdx+"_"+innerIdx,
        //   idx: innerIdx,
        //   name: this.$data.list[this.swiper.activeIndex].hot[hotIdx].hot[innerIdx].name,
        //   content:this.$data.list[this.swiper.activeIndex].hot[hotIdx].hot[innerIdx].content
        // }
        // console.dir(this.$data.currPage)
        // this.tracking(this.$data.currPage.inner_popup.pId,'enter',this.$data.currPage.inner_popup.name,this.$data.currPage.inner_popup.name)
      })
    },
    onSwiperPagePopupClose(){
      this.swiperPopup.show = false
      this.swiperPopup.list = []
      // this.tracking(this.$data.currPage.inner_popup.pId,'leave',this.$data.currPage.inner_popup.name,this.$data.currPage.inner_popup.name)
      // this.$data.currPage.inner_popup = {}
      // this.$data.currPage.inner_popup = null
    },
    /**
     * 多内容弹窗中的滑动
     */
    onPopupSlideChange(e){
      this.swiperPopup.pre = e.activeIndex === 0
      this.swiperPopup.next = e.activeIndex === e.slides.length-1
    },

    onPopupSwiperBeforeDestroy(swiper){
      swiper.destroy()
    },
    onPopupSlideChangeTransitionStart(){

    },
    onPopupSlideChangeTransitionEnd(){

    },
    onPopupPageClick(index){

    },
    onSwiperPagePre(){
      this.swiperPopupView.slidePrev()
    },
    onSwiperPageNext(){
      this.swiperPopupView.slideNext()
    },
    //--------多内容弹窗 End


    getData(name,callback){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      $request({
        url: `/${name}/data.json?v=`+Math.random(), // json文件地址
        method: 'GET',
      }).then((res)=>{

        if(res.book.appid) {
          this.$data.appid = res.book.appid
        }

        if(res.book.urlHead) {
          this.$data.urlHead = res.book.urlHead
        }

        if(res.book.share) {
          this.$data.share = res.book.share;
        }

        if(res.book.title) {
          $("title").html(res.book.title)
        }

        this.$data.imagePreview = res.book.imagePreview;

        let htmlWidth =  window.innerWidth //document.documentElement.clientWidth;
        let htmlHeight = window.innerHeight//document.documentElement.clientHeight;

        console.log(htmlWidth, htmlHeight)

        let parentInnerWidth = window.parent.innerWidth
        // let parentInnerHeight = window.parent.innerHeight

        let iframeElement = window.frameElement;

        if (iframeElement) {
          let topBar = 46
          let bottomBar = 75
          // 获取 iframe 宽度和高度
          //  htmlWidth = iframeElement.clientWidth;

          // let newParentInnerHeight = window.innerHeight//document.documentElement.clientHeight * 0.9
          // if(parentInnerHeight > newParentInnerHeight) {
          //   htmlHeight = newParentInnerHeight
          //   // if (parentInnerHeight-newParentInnerHeight >= 50){
          //   //   bottomBar = 0
          //   // }
          //   // alert("parentInnerHeight:"+parentInnerHeight+" newParentInnerHeight:"+newParentInnerHeight+" bottomBar:"+bottomBar)
          // }else {
          //   htmlHeight = parentInnerHeight
          // }

          // 宽高比
          let ratio = htmlWidth / htmlHeight;


          let appBottom = 0
          if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
            htmlHeight = htmlHeight - (topBar+bottomBar);
            appBottom = bottomBar //浏览器工具栏高度减去页面工具栏
          }else{
            htmlHeight = htmlHeight - (topBar);
          }
          htmlWidth = htmlHeight * ratio;

           // 获取id为app的元素
            let appElement = document.getElementById("app");
            appElement.style.top = topBar+"px"
            appElement.style.bottom = appBottom+"px"
        }

        let img_w = res.book.imgWidth
        let img_h = res.book.imgHeight

        let tempWidth,tempHeight;
        if (img_w/img_h>=htmlWidth / htmlHeight){
          if (img_w > htmlWidth){
            tempWidth = htmlWidth
            tempHeight = (img_h * htmlWidth) / img_w
          } else {
            tempWidth = img_w
            tempHeight = img_h
          }
        } else {
          if (img_h > htmlHeight){
            tempHeight = htmlHeight
            tempWidth = (img_w * htmlHeight) / img_h
          } else {
            tempWidth = img_w
            tempHeight = img_h
          }
        }
        this.$data.imgWidth = tempWidth
        this.$data.imgHeight = tempHeight

        // this.$refs.main.clientHeight
        //获取底部安全区域高度
        // let bottomH = (getComputedStyle(document.documentElement).getPropertyValue("--sab"))
        // bottomH = bottomH.replace("px","")


        this.$data.imgTop = (htmlHeight - this.$data.imgHeight) / 2 + 5
        this.$data.imgRight = (parentInnerWidth - this.$data.imgWidth) / 2

        this.$data.popup_btn_bottom = (htmlHeight - (this.$data.imgHeight+this.$data.imgTop))

        this.$data.cellFontSize = tempWidth / 7.50 + "px";

        if(res.catalogue) {
          this.$data.catalogueInfo=res.catalogue

          if(res.catalogue.list) {
            this.$data.catalogue = res.catalogue.list
            this.$data.catalogue[0].show = true
          }
        }

        if(res.book.wigget) {
          this.$data.wigget = res.book.wigget
        }

        this.$data.list = res.list
        this.$data.maxNum = this.$data.list.filter((item)=>{return !item.isIgnored}).length
        this.$toast.clear();
        this.$nextTick(()=>{
          this.$data.num = this.swiper.activeIndex+1

          this.$nextTick(()=>{

            let cw = this.$data.imgWidth/img_w
            function hotUpdate(hots){
              hots.forEach((hot)=>{

                // console.log(hot, hot.coords)

                let coords = hot.coords.split(",")
                let list = []
                coords.forEach((coord,idx)=>{
                  if (hot.new){
                    if(idx>1){
                      list.push((parseInt(coord)+parseInt(coords[idx-2]))*cw)
                      return
                    }
                  }
                  list.push(coord*cw)
                })

                hot.coords = list.toString()

                if (hot.hot&&hot.hot.length>0){
                  hotUpdate(hot.hot)
                }

                // console.log(hot, hot.coords)

              })
            }

            this.$data.list.forEach((item)=>{
              //热区位置计算
              if (item.hot&&item.hot.length>0){
                let hots = item.hot
                hotUpdate(hots)
              }
              if (item.area&&item.area.length>0){
                let area = item.area
                area.forEach((areaItem)=>{
                  let xywh = areaItem.xywh.split(",")
                  let list = []
                  xywh.forEach((_xywh)=>{
                    list.push(_xywh*cw)
                  })

                  //+((this.$refs.main.offsetWidth - this.$data.imgWidth) / 2)
                  //+((this.$refs.main.offsetHeight - this.$data.imgHeight) / 2)


                  areaItem.x = list[0]+'px'
                  areaItem.y = list[1]+'px'
                  areaItem.w = list[2]+'px'
                  areaItem.h = list[3]+'px'

                  // areaItem.img+="?v="+Math.random()
                })
              }
            })

            // console.dir(this.$refs.img1[0].offsetLeft)
            // console.dir(this.$data.list)
          })

        })
        if (callback)callback()
      })
    }
  }
}
</script>

<style lang="scss">
#app{
  box-sizing: border-box;
}
</style>

<style lang="scss" scoped>

.tip{
  right: 0;
  left: 0;
  z-index: 99;
  color: #333333;
  font-size: 16px;
  position: absolute;
}
.hide-input{
  left: -120%;
  opacity: 0;
  //z-index: 3;
  position: absolute;
}
.test-btn{
  top: 3%;
  right: 5%;
  z-index: 1;
  position: absolute;
}

:deep(.swiper){
  flex: 1;
  width: 100%;
}
.scroll-view{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  &__content{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    //padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    :deep(img){
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
    .imgHot{
      object-fit: contain;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
  }
}
.page-content {
  position: relative;
}
.bottom-bar{
  left: 0;
  right: 0;
  height: 30px;
  bottom: 30px;
  z-index: 2;
  display: flex;
  //background-color: #333333;
  justify-content: center;
  position: absolute;
  .directory-btn{
    top: 50%;
    right: 0;
    height: 25px;
    width: 70px;
    color: #fff;
    line-height: 25px;
    font-size: 10px;
    text-align: center;
    position: absolute;
    background-color: #C00000;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: translateY(-50%);
    span:last-child{
      margin-left: 15px;
    }
  }
}
.tool-bar{
  font-size: 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &__icon{
    width: 15px;
    height: 15px;
    color: #C00000;
  }
  &__text{
    padding: 0 10px;
    span{
      margin: 0 5px;
    }
    span:first-child{
      padding: 0 2px;
      border-radius: 2px;
      border: 1px solid #CACACA;
      background-color: #EBEBED;
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    }
  }
  &__pre,&__next{
    padding: 0 10px;
  }

}

.catalogue{
  position: relative;
  img{
    width: 100%;
    height: 100%;
  }
  &_box{
    top: 14%;
    right: 0;
    bottom: 20%;
    width: 85%;
    position: absolute;
    //background-color: #333333;
    .scroll-view{
      overflow: hidden;
      overflow-y: scroll;
      &__content{
        display: block;
        justify-content: unset;
        align-items: unset;
        position: relative;
        overflow: unset;
      }
    }
  }
  &-cell{
    font-weight: bold;
    display: flex;
    &__num{
      color: #E01C27;
      font-weight: bold;
      font-size: 0.72em;
    }
    &__box{
      flex: 1;
      position:relative;
      margin-top: 5.5%;
      margin-left: 3%;
      &-tip{
        right: 5%;
        text-align: right;
        font-size: 0.20em;
        color: #58595B;
        position: absolute;
        transform: translateY(-100%);
      }
      &-title{
        display: flex;
        color: #E01C27;
        font-size: 0.36em;
        line-height: 1.4em;
        padding-right: 5%;

        justify-content: space-between;
        border-top: 2px solid #E01C27;
        &__db{
          font-weight: bold;
        }
        &__ft{

        }
      }
      &-list{
        color: #E01C27;
        position: relative;
        min-height: 46px;

        &__btn {
          z-index: 1;
          opacity: 1;
          display: flex;
          position: absolute;
          align-items: flex-end;
          font-size: 0.25em;
          transition: all 0s 0.2s;
          svg{
            width: 2em !important;
          }
          span{
            margin-left: 5px;
          }

          &.hide{
            transition-delay: 0s;
            z-index: -1;
            opacity: 0;
          }
        }
        &__drop{
          opacity: 0;
          overflow: hidden;
          max-height: 0;
          transition: all 0.4s;
          margin-right: 5%;
          .drop-item{
            display: flex;
            line-height: 1.1em;
            align-items: center;
            font-size: 0.21em;
            position: relative;
            padding-left: 0.8em;
            margin: 10px 0;
            color: #58595B;

            &::before{
              left: 0;
              top: 0.2em;
              width: 0.4em;
              height: 0.4em;
              border-radius: 50%;
              background-color: #E01C27;
              content: "";
              position: absolute;
            }
            &__line{
              flex: 1;
              height: 0;
              min-width: 0;
              margin: 0 0.8em;
              border-top: 1px dashed #58595B;
            }
          }
          &.show{
            opacity: 1;
            max-height: 325px;
          }
        }
      }
    }
  }

  .catalogue-cell + .catalogue-cell{
    margin-top: 5px;
  }
}

:deep(.exhibit_popup){
  width: 100%;
  height: 100%;
}
.exhibit_popup__box{
  width: 100%;
  height: 100%;
  position: relative;
  &-close{
    z-index: 2;
    top: 0.2em;
    right: 0.2em;
    width: 0.4em;
    height: 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBEBED;
    border-radius: 5px;
    position: absolute;
    box-shadow: 0 2px 0px rgba(0,0,0,0.3);
    svg{
      width: 0.2em !important;
      height: 0.2em !important;
    }
  }
  &-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    img{
      object-fit: contain;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
    //:deep(.van-image){
    //  width: 100%;
    //  height: 100%;
    //}
    &_btn-group{
      z-index: 2;
      display: flex;
      position: absolute;
      bottom: 0.4em;
      .pre_btn, .next_btn{
        display: flex;
        font-size: 0.16rem;
        position: relative;
        align-items: center;
        margin: 0 0.1rem;

        svg{
          width: 0.15rem !important;
          height: 0.15rem !important;
          color: #C00000;
          margin: 0.1rem;
        }

        &.hide{
          opacity: 0;
          z-index: -1;
        }
      }
      .pre_btn{

      }
      .next_btn{

      }
      .back_btn{
        width: 1.57rem;
        height: 0.36rem;
        color: #58595B;
        border-radius: 5px;
        text-align: center;
        font-size: 0.16rem;
        line-height: 0.36rem;
        font-weight: bold;
        background-color: #EBEBED;
        margin: 0 0.15rem;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }
  &-back{
    z-index: 2;
    left: 50%;
    bottom: 0.4em;
    width: 10.17em;
    height: 2.37em;
    color: #58595B;
    position: absolute;
    border-radius: 5px;
    text-align: center;
    font-size: 0.20em;
    line-height: 2.37em;
    font-weight: bold;
    background-color: #EBEBED;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.3);
    transform: translateX(-50%);
  }
}

.area_box{
  position: absolute;
  img{
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
}
</style>

import axios from 'axios';

const service = axios.create({
    baseURL: 'data',
    url:"./",
    timeout: 30000,
});



// 请求拦截
service.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

// 响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        return Promise.resolve(res);
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default service;
